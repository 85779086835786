import React from 'react';
import { useQuery, gql } from '@apollo/client';

import HandleLoading from '@hoc/handle-loading/handle-loading';
import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import Seo from '@components/seo/seo';

import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import './wp-styles.scss';


const PAGE_QUERY = gql`
    query BlogPostsQuery($slug: String) {
        offerBy(slug: $slug) {
            content
        }
    }
`;
const PrivacyPolicy = () => {


  const { loading, error, data } = useQuery(PAGE_QUERY, {
    variables: {slug: 'pozyczka-leasingowa-i-leasing-finansowy'}
  });


  return (
    <MainLayout>

      <BreadCrumbs />

      <Seo
        title='Leasing finansowy i pożyczka leasingowa'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
      />

      <section className={`${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        <article className={s_text.text_view}>
        <HandleLoading loading={loading} error={error}>

          <h1>Leasing finansowy i pożyczka leasingowa</h1>

          <div dangerouslySetInnerHTML={{ __html: data?.offerBy.content }}>
            </div>

         </HandleLoading>

          {/* <div className={s_text.double}>
            <div>
              <h2>Pożyczka leasingowa i jej zalety</h2>
              <p>
                Pożyczka leasingowa to popularna alternatywa dla kredytu, służąca finansowaniu środków trwałych.
                Nie jest ona jednak udzielana przez banki, a firmy leasingowe. Stanowi korzystne rozwiązanie
                dla przedsiębiorców, którzy nie są płatnikami VAT, a także tych, którzy ubiegają się o dotacje
                unijne lub chcą skorzystać z dłuższego okresu finansowania.
              </p>
              <p>
                Należy zaznaczyć, że w ramach takiej usługi, właścicielem środka trwałego od
                samego początku jest Pożyczkobiorca.
              </p>
            </div>
            <div>
              <p>
                Na pożyczkę leasingową warto zdecydować się z kilku istotnych względów:
              </p>
              <ul className={s_text.checkmark_list}>
                <li>Brak ograniczeń prawnych dotyczących czasu jej trwania (od kilku miesięcy do nawet kilku lat)</li>
                <li>Możliwość dokonania amortyzacji przedmiotu (jednorazowo lub w miesięcznych ratach)</li>
                <li>Raty pożyczki są zwolnione z podatku VAT, co powoduje niższe miesięczne należności (VAT odliczany jest jednorazowo)</li>
                <li>Odsetkowa część raty, amortyzacje środka trwałego oraz ewentualne prowizje mogą zostać wliczone w koszty uzyskania przychodu przedsiębiorcy</li>
                <li>Mniej rygorystyczne procedury oceny zdolności finansowej niż w przypadku kredytu bankowego</li>
              </ul>
            </div>
          </div>

          <div className={s_text.double}>
            <div>
              <h2>Leasing finansowy i jego zalety</h2>
              <p>
                Leasing finansowy (kapitałowy) to korzystna forma finansowania w przypadku gdy dany środek
                trwały może zostać nabyty na fakturę bez podatku VAT (faktura VAT marża). Traktuje się go
                jako „dostawa towarów”, dlatego też objęty jest taką samą stawką VAT jak przedmiot leasingu.
                Oferta ta kierowana jest również do przedsiębiorców chcących skorzystać z uprzywilejowanej
                amortyzacji lub tych, którym zależy na możliwości naliczenia podatku VAT i odsetek od całej
                wartości przedmiotu, już w pierwszym miesiącu zawarcia umowy.
              </p>
              <p>
                W odróżnieniu od pożyczki leasingowej, przez cały okres trwania leasingu finansowego własność
                przedmiotu należy do Leasingodawcy. Nie ma możliwości jego wykupu - dopiero wraz z zapłatą
                ostatniej raty przedmiot ten staje się własnością Leasingobiorcy. Podatek VAT naliczany
                jest w całości, jednorazowo na początku transakcji. W leasingu finansowym rata dzieli się
                na dwie części: kapitałową oraz odsetkową. Druga z nich stanowi koszt uzyskania przychodu Korzystającego (Leasingobiorcy).
              </p>
            </div>
            <div>
              <p>
                Leasing finansowy to korzystne rozwiązanie, ponieważ:
              </p>
              <ul className={s_text.checkmark_list}>
                <li>Stwarza możliwość optymalizacji podatkowej</li>
                <li>Nie obniża zdolności kredytowej Leasingobiorcy</li>
                <li>Pozwala dokonać amortyzacji przedmiotu (m.in amortyzacji jednorazowej przyspieszonej)</li>
                <li>W koszty uzyskania przychodu można włączyć: amortyzację, odsetkową część raty oraz koszty eksploatacji</li>
                <li>Nie istnieją ograniczenia w zakresie trwania umowy</li>
              </ul>
              <p>
                Jeśli chcą Państwo uzyskać więcej informacji dotyczących leasingu finansowego lub
                pożyczki leasingowej serdecznie zapraszam do kontaktu telefonicznego i e-mailowego.
                Pozostaję do Waszej dyspozycji oraz służę swoją wiedzą i wsparciem!
              </p>
            </div>
          </div> */}

        </article>

      </section>

      <ContactSection />
      <MapSection />

    </MainLayout>
  );

}

export default PrivacyPolicy;
